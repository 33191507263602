import React, { useEffect, useState } from 'react';
import Logo from '../res/img/logo.svg';
import DarkLogo from '../res/img/darkLogo.svg';
import { websiteURL } from '../constants';

const LogoComponent = ({ isDarkMode }) => {

    const [webHomeURL, setWebHomeURL] = useState(websiteURL);

    function redirectHome() {
        window.location.href = webHomeURL;
    }

    useEffect(() => {

        /* set home page url */
        if (sessionStorage.getItem('email')) {
            setWebHomeURL(`${websiteURL}/home`);
        }
        /* set home page url */

    });

    return <img alt='logo' src={isDarkMode === "true" ? DarkLogo : Logo} className="mr-3 h-9 cursor-pointer" onClick={redirectHome} />;
};

export default LogoComponent;
