import {INRSymbol, INRMonthCost, INRYearCost, INRFreeCost, USDSymbol} from '../constants';

export const getCurrencyOptionData = (props) => {
    let data = {};
    if (sessionStorage.getItem('currencyOption') == 'INR') {
        data['currency'] = 'INR';
        data['currency_symbol'] = INRSymbol;
        if (props.data.type == 'Monthly Plan') {
            data['amount'] = INRMonthCost;
        }
        if (props.data.type == 'Yearly Plan') {
            data['amount'] = INRYearCost;
        }
        if (props.data.type == 'Free Plan') {
            data['amount'] = INRFreeCost;
        }
    }
    if (sessionStorage.getItem('currencyOption') == 'USD') {
        data['currency'] = 'USD';
        data['currency_symbol'] = USDSymbol;
        data['amount'] = props.data.cost;
    }
    return data;
};