import React, { useEffect, useMemo, useState } from 'react';
import Header from '../components/header';
import Footers from '../components/footers';
import { Button, Label, Select } from 'flowbite-react';
import axios from 'axios';
import { amountInZarOne, amountInZarTwo, flutterwaveEnabled, flutterwavePlanIdOne, flutterwavePlanIdTwo, flutterwavePublicKey, logo, MonthCost, name, paypalEnabled, paypalPlanIdOne, paypalPlanIdTwo, paystackEnabled, paystackPlanIdOne, paystackPlanIdTwo, razorpayEnabled, razorpayPlanIdOne, razorpayPlanIdTwo, serverURL, stripeEnabled, stripePlanIdOne, stripePlanIdTwo, YearCost, websiteURL } from '../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import countryList from 'react-select-country-list'
import { toast } from 'react-toastify';
import { AiOutlineLoading } from 'react-icons/ai';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';

import {getCurrencyOptionData} from '../utils/helpers';

const Payment = () => {

    const [email, setEmail] = useState(sessionStorage.getItem('email'));
    const [phone, setPhone] = useState('');
    const [address, setAdress] = useState('');
    const [mName, setName] = useState(sessionStorage.getItem('mName'));
    const [lastName, setLastName] = useState('');
    const [post, setPost] = useState('');
    const [country, setCountry] = useState('');
    const [admin, setAdmin] = useState('');
    const options = useMemo(() => countryList().getData(), [])
    const { state } = useLocation();
    const { plan } = state || {};
    const [processing, setProcessing] = useState('');
    const navigate = useNavigate();
    const [showPaymentForm, setShowPaymentForm] = useState(true);
    const [uid, setUID] = useState(sessionStorage.getItem('uid'));

    const [pricingData, setPricingData] = useState(null);



    useEffect(() => {
        if (!plan) {
            navigate("/pricing");
        }
        let order_amount = 0;
        if (plan === 'Monthly Plan') {
            order_amount = MonthCost;
        } else {
            order_amount = YearCost;
        }
        let d = {data: {type: plan, cost: order_amount}};
        setPricingData(getCurrencyOptionData(d));

        if(pricingData?.currency == 'INR'){
            setCountry('IN');
            setShowPaymentForm(false);
        }else{
            setShowPaymentForm(true);
        }

    }, [pricingData]);

    function openCustomLink(url) {
        let weburl = `${url} My Email: ${email}, My Country: ${country}, My Name: ${mName}, My Selected Plan: ${plan}`;
        window.open(weburl, '_blank', 'noopener,noreferrer');
    }

    function generateAlphanumericString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


    //open cashfree link
    async function openCashfreeLink() {
        if (!email || !mName || !lastName || !post || !address || !country || !admin) {
            showToast('Please fill in all required fields');
            return;
        }
        setProcessing('cashfree')
        let order_amount = 0;
        if (plan === 'Monthly Plan') {
            order_amount = MonthCost;
        } else {
            order_amount = YearCost;
        }
        let order_id = 'COURSESMAKER_CASHFREE_' + generateAlphanumericString(20);
        let order_note = 'Purchased Plan: ' + plan;
        const dataToSend = {
            order_id: order_id,
            order_amount: order_amount,
            order_currency: 'USD',
            customer_details: {
                customer_id: uid,
                customer_email: email,
                customer_phone: phone,
                customer_name: mName
            },
            order_meta: {
                return_url: websiteURL + '/successful',
            },
            order_note: order_note
        };
        try {
            const postURL = 'https://sandbox.cashfree.com/pg/orders';
            const res = await axios.post(postURL, dataToSend);
            console.log(res);
            /* sessionStorage.setItem('paystack', res.data.id);
            sessionStorage.setItem('method', 'cashfree');
            sessionStorage.setItem('plan', plan);
            setProcessing('')
            window.location.href = res.order_meta.return_url; */

        } catch (error) {
            //DO NOTHING
        }
    }

    async function openPayuLink(link) {

        var apiKey = "JP***g";
        var salt = "cpblo4";
        var txnId = "txnid18220148896";
        var amount = "10.0";
        var productInfo = "iPhone";
        var firstName = "PayU User";
        var email = "test@gmail.com";
        var phone = "9876543210";
        var surl = "https://test-payment-middleware.payu.in/simulatorResponse";
        var furl = "https://test-payment-middleware.payu.in/simulatorResponse";
        var hashString = apiKey + "|" + txnId + "|" + amount + "|" + productInfo + "|" + firstName + "|" + email + "|||||||||||" /* +salt*/;
        var hash = sha512(hashString);
        hash = "cef50b8b69326fb1c100e5be0437e9574340621d324794e1e956289cb5e87bb9e5ef1c965d54322b417f8e16648ee481b842b9195c5e2b4d7aabd1fee9827e02";

        var xhr = new XMLHttpRequest();
        xhr.open("POST", "https://test.payu.in/_payment", true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                console.log(xhr.responseText);
            }
        };

        var formData = new FormData();
        formData.append("key", apiKey);
        formData.append("txnid", txnId);
        formData.append("amount", amount);
        formData.append("productinfo", productInfo);
        formData.append("firstname", firstName);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("surl", surl);
        formData.append("furl", furl);
        formData.append("hash", hash);

        xhr.send(formData);

    }

    function sha512(value) {
        var CryptoJS = require("crypto-js");

        var hash = CryptoJS.SHA512(value);
        return hash.toString(CryptoJS.enc.Hex);
    }


    async function startPaystack() {
        if (!email || !mName || !lastName || !post || !address || !country || !admin) {
            showToast('Please fill in all required fields');
            return;
        }
        setProcessing('paystack')
        let planId = paystackPlanIdTwo;
        let amountInZar = amountInZarTwo;
        if (plan === 'Monthly Plan') {
            planId = paystackPlanIdOne;
            amountInZar = amountInZarOne;
        }
        const dataToSend = {
            planId: planId,
            amountInZar,
            email: email
        };
        try {
            const postURL = serverURL + '/api/paystackpayment';
            const res = await axios.post(postURL, dataToSend);
            sessionStorage.setItem('paystack', res.data.id);
            sessionStorage.setItem('method', 'paystack');
            sessionStorage.setItem('plan', plan);
            setProcessing('')
            window.location.href = res.data.url;

        } catch (error) {
            //DO NOTHING
        }
    }

    async function startStripe() {
        if (!email || !mName || !lastName || !post || !address || !country || !admin) {
            showToast('Please fill in all required fields');
            return;
        }
        setProcessing('stripe')
        let planId = stripePlanIdTwo;
        if (plan === 'Monthly Plan') {
            planId = stripePlanIdOne;
        }
        const dataToSend = {
            planId: planId
        };
        try {
            const postURL = serverURL + '/api/stripepayment';
            const res = await axios.post(postURL, dataToSend);
            sessionStorage.setItem('stripe', res.data.id);
            sessionStorage.setItem('method', 'stripe');
            sessionStorage.setItem('plan', plan);
            setProcessing('')
            window.location.href = res.data.url;

        } catch (error) {
            //DO NOTHING
        }
    }

    const config = {
        public_key: flutterwavePublicKey,
        tx_ref: Date.now(),
        currency: 'USD',
        amount: plan === 'Monthly Plan' ? MonthCost : YearCost,
        payment_options: "card",
        payment_plan: plan === 'Monthly Plan' ? flutterwavePlanIdOne : flutterwavePlanIdTwo,
        customer: {
            email: email,
            name: mName,
        },
        customizations: {
            title: name,
            description: 'Subscription Payment',
            logo: logo,
        },
    };

    const handleFlutterPayment = useFlutterwave(config);

    async function startRazorpay() {

        if (!email || !mName || !lastName || !post || !address || !country || !admin) {
            showToast('Please fill in all required fields');
            return;
        }
        setProcessing('razorpay')
        let fullAddress = address + ' ' + admin + ' ' + post + ' ' + country;
        let planId = razorpayPlanIdTwo;
        if (plan === 'Monthly Plan') {
            planId = razorpayPlanIdOne;
        }
        const dataToSend = {
            plan: planId,
            email: email,
            fullAddress: fullAddress
        };
        try {
            const postURL = serverURL + '/api/razorpaycreate';
            const res = await axios.post(postURL, dataToSend);
            sessionStorage.setItem('method', 'razorpay');
            setProcessing('')
            sessionStorage.setItem('plan', plan);
            window.open(res.data.short_url, '_blank');
            navigate('/pending', { state: { sub: res.data.id, link: res.data.short_url } });
        } catch (error) {
            //DO NOTHING
        }
    }

    async function startPayPal() {

        if (!email || !mName || !lastName || !post || !address || !country || !admin) {
            showToast('Please fill in all required fields');
            return;
        }
        setProcessing('paypal')
        let planId = paypalPlanIdTwo;
        if (plan === 'Monthly Plan') {
            planId = paypalPlanIdOne;
        }

        const dataToSend = {
            planId: planId,
            email: email,
            name: mName,
            lastName: lastName,
            post: post,
            address: address,
            country: country,
            brand: name,
            admin: admin
        };
        try {
            const postURL = serverURL + '/api/paypal';
            const res = await axios.post(postURL, dataToSend);
            sessionStorage.setItem('method', 'paypal');
            sessionStorage.setItem('plan', plan);
            setProcessing('')
            const links = res.data.links;
            const approveLink = links.find(link => link.rel === "approve");
            const approveHref = approveLink ? approveLink.href : null;
            window.location.href = approveHref;

        } catch (error) {
            startPayPal()
        }
    }


    const showToast = async (msg) => {
        toast(msg, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }

    return (
        <div className='h-screen flex flex-col'>
            <Header isHome={true} className='sticky top-0 z-50' />
            <div className='dark:bg-black flex-1'>
                <div className='flex-1 flex items-center justify-center py-0'>



                    <form className="max-w-sm m-auto py-4 no-scrollbar">
                        <p className='text-center font-black text-4xl text-black dark:text-white'>Payment</p>
                        <p className='text-center font-normal text-black py-4 dark:text-white'>Enter your details and select payment gateway to continue</p>
                        <div className='py-6'>
                            <div className='mb-6'>
                                <div className="mb-2 block">
                                    <Label className="font-bold text-black dark:text-white" htmlFor="email1" value="Email" />
                                </div>
                                <input value={email} onChange={(e) => setEmail(e.target.value)} className='focus:ring-black focus:border-black border border-black font-normal bg-white rounded-none block w-full dark:bg-black dark:border-white dark:text-white' id="email1" type="email" />
                            </div>

                            <div className='mb-6'>
                                <div className="mb-2 block">
                                    <Label className="font-bold text-black dark:text-white" htmlFor="phone1" value="Phone" />
                                </div>
                                <input value={phone} onChange={(e) => setPhone(e.target.value)} className='focus:ring-black focus:border-black border border-black font-normal bg-white rounded-none block w-full dark:bg-black dark:border-white dark:text-white' id="phone1" type="text" />
                            </div>

                            <div className='mb-6'>
                                <div className="mb-2 block">
                                    <Label className="font-bold text-black dark:text-white" htmlFor="name1" value="First Name" />
                                </div>
                                <input value={mName} onChange={(e) => setName(e.target.value)} className='focus:ring-black focus:border-black border border-black font-normal bg-white rounded-none block w-full dark:bg-black dark:border-white dark:text-white' id="name1" type="text" />
                            </div>
                            <div className='mb-6'>
                                <div className="mb-2 block">
                                    <Label className="font-bold text-black dark:text-white" htmlFor="name2" value="Last Name" />
                                </div>
                                <input onChange={(e) => setLastName(e.target.value)} className='focus:ring-black focus:border-black border border-black font-normal bg-white rounded-none block w-full dark:bg-black dark:border-white dark:text-white' id="name2" type="text" />
                            </div>
                            <div className='mb-6'>
                                <div className="mb-2 block">
                                    <Label className="font-bold text-black dark:text-white" htmlFor="address1" value="Address" />
                                </div>
                                <input onChange={(e) => setAdress(e.target.value)} className='focus:ring-black focus:border-black border border-black font-normal bg-white rounded-none block w-full dark:bg-black dark:border-white dark:text-white' id="address1" type="text" />
                            </div>
                            <div className='mb-6'>
                                <div className="mb-2 block">
                                    <Label className="font-bold text-black dark:text-white" htmlFor="post1" value="Postal Code" />
                                </div>
                                <input onChange={(e) => setPost(e.target.value)} className='focus:ring-black focus:border-black border border-black font-normal bg-white rounded-none block w-full dark:bg-black dark:border-white dark:text-white' id="post1" type="number" />
                            </div>
                            <div className='mb-6'>
                                <div className="mb-2 block">
                                    <Label className="font-bold text-black dark:text-white" htmlFor="code1" value="Country Code" />
                                </div>
                                <Select
                                    class='rounded-none border-black focus:ring-black focus:border-black border  font-normal bg-white  block w-full dark:bg-black dark:border-white dark:text-white'
                                    value={country} onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        const selectedOption = options.find(
                                            (country) => country.value === selectedValue
                                        );
                                        setCountry(selectedOption.value);
                                        setAdmin(selectedOption.label);

                                        if (selectedValue == 'IN') {
                                            setShowPaymentForm(false);
                                        } else {
                                            setShowPaymentForm(true);
                                        }
                                    }}>
                                    <option value="">--Select Country--</option>
                                    {options.map((country) => (
                                        <option key={country.value} value={country.value}>
                                            {country.label}
                                        </option>
                                    ))}
                                </Select>
                            </div>

                            {!showPaymentForm &&
                                <>
                                    <Button className='my-2 items-center justify-center text-center dark:bg-white dark:text-black bg-black text-white font-bold rounded-none w-full enabled:hover:bg-black enabled:focus:bg-black enabled:focus:ring-transparent dark:enabled:hover:bg-white dark:enabled:focus:bg-white dark:enabled:focus:ring-transparent' onClick={() => {openCustomLink('https://wa.me/919136728346?text=Hi!%20Welcome%20to%20Coursesmaker.com.%0APlease%20let%20us%20know%20if%20you%E2%80%99ve%20selected%20the%20monthly%20or%20yearly%20plan%2C%20and%20share%20your%20email%20ID.%0AOur%20team%20will%20then%20activate%20your%20plan.') }}>WhatsApp Us</Button>
                                </>
                            }

                            {showPaymentForm &&
                                <>
                                    {stripeEnabled ? <Button isProcessing={processing === 'stripe'} processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />} className='my-2 items-center justify-center text-center dark:bg-white dark:text-black bg-black text-white font-bold rounded-none w-full enabled:hover:bg-black enabled:focus:bg-black enabled:focus:ring-transparent dark:enabled:hover:bg-white dark:enabled:focus:bg-white dark:enabled:focus:ring-transparent' onClick={startStripe}>Stripe</Button>
                                        : <></>}
                                    {paypalEnabled ? <Button isProcessing={processing === 'paypal'} processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />} className='my-2 items-center justify-center text-center dark:bg-white dark:text-black bg-black text-white font-bold rounded-none w-full enabled:hover:bg-black enabled:focus:bg-black enabled:focus:ring-transparent dark:enabled:hover:bg-white dark:enabled:focus:bg-white dark:enabled:focus:ring-transparent' onClick={startPayPal}>PayPal</Button>
                                        : <></>}
                                    {razorpayEnabled ? <Button isProcessing={processing === 'razorpay'} processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />} className='my-2 items-center justify-center text-center dark:bg-white dark:text-black bg-black text-white font-bold rounded-none w-full enabled:hover:bg-black enabled:focus:bg-black enabled:focus:ring-transparent dark:enabled:hover:bg-white dark:enabled:focus:bg-white dark:enabled:focus:ring-transparent' onClick={startRazorpay}>Razorpay</Button>
                                        : <></>}
                                    {paystackEnabled ? <Button isProcessing={processing === 'paystack'} processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />} className='my-2 items-center justify-center text-center dark:bg-white dark:text-black bg-black text-white font-bold rounded-none w-full enabled:hover:bg-black enabled:focus:bg-black enabled:focus:ring-transparent dark:enabled:hover:bg-white dark:enabled:focus:bg-white dark:enabled:focus:ring-transparent' onClick={startPaystack}>Paystack</Button>
                                        : <></>}
                                    {flutterwaveEnabled ? <Button isProcessing={processing === 'paystack'} processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />} className='my-2 items-center justify-center text-center dark:bg-white dark:text-black bg-black text-white font-bold rounded-none w-full enabled:hover:bg-black enabled:focus:bg-black enabled:focus:ring-transparent dark:enabled:hover:bg-white dark:enabled:focus:bg-white dark:enabled:focus:ring-transparent' onClick={() => {
                                        handleFlutterPayment({
                                            callback: (response) => {
                                                sessionStorage.setItem('stripe', response.transaction_id);
                                                sessionStorage.setItem('method', 'flutterwave');
                                                sessionStorage.setItem('plan', plan);
                                                navigate("/success");
                                                closePaymentModal();
                                            },
                                            onClose: () => { },
                                        });
                                    }}>Flutterwave</Button>
                                        : <></>}

                                </>
                            }
                        </div>
                    </form>

                </div>

                {/* for indian customers payments */}
                <p className='text-center'>If you're in India and want to purchase our monthly or yearly plan, please contact us on WhatsApp : <a className='hyperlink text-pink' href='https://wa.me/919136728346?text=Hi!%20Welcome%20to%20Coursesmaker.com.%0APlease%20let%20us%20know%20if%20you%E2%80%99ve%20selected%20the%20monthly%20or%20yearly%20plan%2C%20and%20share%20your%20email%20ID.%0AOur%20team%20will%20then%20activate%20your%20plan.'>+91-9136728346</a></p>
                {/* for indian customers payments */}
            </div >
            <Footers className='sticky bottom-0 z-50' />
        </div >
    );
};

export default Payment;
